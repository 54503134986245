// src/components/games/config/badgeConfigs.js
export const BADGE_CONFIGS = {
  triviaNightLoser: {
    backgroundColor: '#E53E3E',
    emoji: '📚🤔💡',
    title: 'Keep Learning!',
    subtitle: 'Try again!',
    buttonColor: 'red.500',
    buttonHoverColor: 'red.600',
    shadowColor: 'rgba(255,0,0,0.3)',
    downloadFileName: 'trivia_rekt_badge.png',
    shareText: 'I got beat at Trivia Night on TimechainStats.com! 📚🤔💡'
  },
  triviaNightAverage: {
    backgroundColor: '#FFA500',
    emoji: '📚🧠💡',
    title: 'Good Effort!',
    subtitle: 'Keep improving!',
    buttonColor: 'orange.500',
    buttonHoverColor: 'orange.600',
    shadowColor: 'rgba(255,165,0,0.3)',
    downloadFileName: 'trivia_average_badge.png',
    shareText: 'I held my own at Trivia Night on TimechainStats.com! 📚🧠💡'
  },
  triviaNightWinner: {
    backgroundColor: '#4CAF50',
    emoji: '🏆📚🧠',
    title: 'Great Job!',
    subtitle: 'Play again!',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'trivia_master_badge.png',
    shareText: 'I mastered Bitcoin trivia on TimechainStats.com! 🏆📚🧠'
  },

  // New badges for 10-question basic mode
  triviaNightPerfect: {
    backgroundColor: '#FFD700',
    emoji: '🌟📚🧠',
    title: 'Perfect Score!',
    subtitle: '10/10 Correct!',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 215, 0, 0.3)',
    downloadFileName: 'trivia_perfect_badge.png',
    shareText: 'I aced Bitcoin Trivia Night with a perfect score on TimechainStats.com! 🌟📚🧠'
  },
  triviaNightNovice: {
    backgroundColor: '#87CEEB',
    emoji: '🔰📚🧠',
    title: 'Bitcoin Novice',
    subtitle: 'You\'re on the right track!',
    buttonColor: 'blue.300',
    buttonHoverColor: 'blue.400',
    shadowColor: 'rgba(135, 206, 235, 0.3)',
    downloadFileName: 'trivia_novice_badge.png',
    shareText: 'I\'m learning about Bitcoin on TimechainStats.com Trivia Night! 🔰📚🧠'
  },

  // New badges for survivor mode
  triviaNightSurvivor5: {
    backgroundColor: '#9370DB',
    emoji: '🛡️📚5️⃣',
    title: 'Survivor: Level 5',
    subtitle: 'Survived 5 questions!',
    buttonColor: 'purple.400',
    buttonHoverColor: 'purple.500',
    shadowColor: 'rgba(147, 112, 219, 0.3)',
    downloadFileName: 'trivia_survivor_5_badge.png',
    shareText: 'I survived 5 questions in Bitcoin Trivia Night Survivor Mode on TimechainStats.com! 🛡️📚5️⃣'
  },
  triviaNightSurvivor10: {
    backgroundColor: '#20B2AA',
    emoji: '🛡️📚🔟',
    title: 'Survivor: Level 10',
    subtitle: 'Survived 10 questions!',
    buttonColor: 'teal.400',
    buttonHoverColor: 'teal.500',
    shadowColor: 'rgba(32, 178, 170, 0.3)',
    downloadFileName: 'trivia_survivor_10_badge.png',
    shareText: 'I survived 10 questions in Bitcoin Trivia Night Survivor Mode on TimechainStats.com! 🛡️📚🔟'
  },
  triviaNightSurvivor20: {
    backgroundColor: '#FF6347',
    emoji: '🛡️📚2️⃣0️⃣',
    title: 'Survivor: Level 20',
    subtitle: 'Survived 20 questions!',
    buttonColor: 'red.400',
    buttonHoverColor: 'red.500',
    shadowColor: 'rgba(255, 99, 71, 0.3)',
    downloadFileName: 'trivia_survivor_20_badge.png',
    shareText: 'I survived 20 questions in Bitcoin Trivia Night Survivor Mode on TimechainStats.com! 🛡️📚2️⃣0️⃣'
  },
  triviaNightSurvivorLegend: {
    backgroundColor: '#FFD700',
    emoji: '🏆🛡️💯',
    title: 'Survivor Legend',
    subtitle: 'Survived 50+ questions!',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 215, 0, 0.3)',
    downloadFileName: 'trivia_survivor_legend_badge.png',
    shareText: 'I\'m a Bitcoin Trivia Night Survivor Legend on TimechainStats.com! Survived 50+ questions! 🏆🛡️💯'
  },
  // Get Rekt Simulator
  predictionWinner: {
    backgroundColor: '#4CAF50',
    emoji: '🏆💰🚀',
    title: 'Winner, Winner!',
    subtitle: 'You won a prediction!',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'prediction_master_badge.png',
    shareText: 'I won a prediction in the Get Rekt Simulator on TimechainStats.com! 🏆💰🚀'
  },
  getRekt: {
    backgroundColor: '#E53E3E',
    emoji: '🐷💔💸',
    title: 'You got REKT!',
    subtitle: '0 Credits Remaining',
    buttonColor: 'red.500',
    buttonHoverColor: 'red.600',
    shadowColor: 'rgba(255,0,0,0.3)',
    downloadFileName: 'rekt_badge.png',
    shareText: 'I got REKT in the Get Rekt Simulator on TimechainStats.com! 🐷💔💸'
  },
  octopusVibes: {
    backgroundColor: '#6A0DAD',
    emoji: '🐙💰🌟',
    title: 'Octopus Vibes!',
    subtitle: '100,000 Credits Milestone',
    buttonColor: 'purple.500',
    buttonHoverColor: 'purple.600',
    shadowColor: 'rgba(106, 13, 173, 0.3)',
    downloadFileName: 'octopus_vibes_badge.png',
    shareText: 'I reached 100,000 credits in the Get Rekt Simulator on TimechainStats.com! 🐙💰🌟'
  },
  // Miner Tycoon Badges - START
  minerTycoonFirstBlock: {
    backgroundColor: '#4CAF50',
    emoji: '⛏️🎉💰',
    title: 'First Block Mined!',
    subtitle: 'You\'re on your way!',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'first_block_badge.png',
    shareText: 'I mined my first block in Miner Tycoon on TimechainStats.com! ⛏️🎉💰'
  },
  minerTycoonAsicMiner: {
    backgroundColor: '#FFA500',
    emoji: '🖥️💨💰',
    title: 'ASIC Power!',
    subtitle: 'Upgraded to ASIC',
    buttonColor: 'orange.500',
    buttonHoverColor: 'orange.600',
    shadowColor: 'rgba(255, 165, 0, 0.3)',
    downloadFileName: 'asic_miner_badge.png',
    shareText: 'I upgraded to an ASIC miner in Miner Tycoon on TimechainStats.com! 🖥️💨💰'
  },
  minerTycoonMiningFarm: {
    backgroundColor: '#1E90FF',
    emoji: '🏭🔌💰',
    title: 'Farm Established!',
    subtitle: 'Built a Mining Farm',
    buttonColor: 'blue.500',
    buttonHoverColor: 'blue.600',
    shadowColor: 'rgba(30, 144, 255, 0.3)',
    downloadFileName: 'mining_farm_badge.png',
    shareText: 'I established a mining farm in Miner Tycoon on TimechainStats.com! 🏭🔌💰'
  },
  minerTycoonBitcoinMillionaire: {
    backgroundColor: '#FFD700',
    emoji: '💰🤑🎉',
    title: 'Bitcoin Millionaire!',
    subtitle: '1,000,000 Satoshis',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 215, 0, 0.3)',
    downloadFileName: 'bitcoin_millionaire_badge.png',
    shareText: 'I became a Bitcoin millionaire in Miner Tycoon on TimechainStats.com! 💰🤑🎉'
  },
  difficultyMaster: {
    backgroundColor: '#4CAF50',
    emoji: '🏆⛏️⚖️',
    title: 'Difficulty Master',
    subtitle: 'Expert block time management',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'difficulty_master_badge.png',
    shareText: 'I mastered difficulty adjustments on TimechainStats.com! 🏆⛏️⚖️'
  },
  difficultyProficient: {
    backgroundColor: '#FFA500',
    emoji: '🔧⛏️⚖️',
    title: 'Difficulty Proficient',
    subtitle: 'Skilled block time management',
    buttonColor: 'orange.500',
    buttonHoverColor: 'orange.600',
    shadowColor: 'rgba(255, 165, 0, 0.3)',
    downloadFileName: 'difficulty_proficient_badge.png',
    shareText: 'I\'m proficient at difficulty adjustments on TimechainStats.com! 🔧⛏️⚖️'
  },
  difficultyNovice: {
    backgroundColor: '#3498db',
    emoji: '🔰⛏️⚖️',
    title: 'Difficulty Novice',
    subtitle: 'Learning block time management',
    buttonColor: 'blue.500',
    buttonHoverColor: 'blue.600',
    shadowColor: 'rgba(52, 152, 219, 0.3)',
    downloadFileName: 'difficulty_novice_badge.png',
    shareText: 'I\'m learning difficulty adjustments on TimechainStats.com! 🔰⛏️⚖️'
  },
  // Miner Tycoon Badges - END
  satoshiGenesis: {
    backgroundColor: '#FFD700',
    emoji: '🌱🔗💰',
    title: 'Satoshi Genesis',
    subtitle: 'First block mined',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 215, 0, 0.3)',
    downloadFileName: 'satoshi_genesis_badge.png',
    shareText: 'I started the Bitcoin revolution in the Adoption Simulation on TimechainStats.com! 🌱🔗💰'
  },
  earlyAdopter: {
    backgroundColor: '#4CAF50',
    emoji: '🚀🔗💰',
    title: 'Early Adopter',
    subtitle: '10% adoption reached',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'early_adopter_badge.png',
    shareText: 'I\'m an early Bitcoin adopter in the Adoption Simulation on TimechainStats.com! 🚀🔗💰'
  },
  bitcoinMajority: {
    backgroundColor: '#2196F3',
    emoji: '🌊🔗💰',
    title: 'Bitcoin Majority',
    subtitle: '51% adoption reached',
    buttonColor: 'blue.500',
    buttonHoverColor: 'blue.600',
    shadowColor: 'rgba(33, 150, 243, 0.3)',
    downloadFileName: 'bitcoin_majority_badge.png',
    shareText: 'Bitcoin reached majority adoption in my Adoption Simulation on TimechainStats.com! 🌊🔗💰'
  },
  hyperbitcoinization: {
    backgroundColor: '#FF9800',
    emoji: '🌍🔗💰',
    title: 'Hyperbitcoinization',
    subtitle: '100% adoption is near',
    buttonColor: 'orange.500',
    buttonHoverColor: 'orange.600',
    shadowColor: 'rgba(255, 152, 0, 0.3)',
    downloadFileName: 'hyperbitcoinization_badge.png',
    shareText: 'I achieved hyperbitcoinization in the Adoption Simulation on TimechainStats.com! 🌍🔗💰'
  },
  resilientNetwork: {
    backgroundColor: '#9C27B0',
    emoji: '💪🔗🛡️',
    title: 'Resilient Network',
    subtitle: 'Survived 10 reversions',
    buttonColor: 'purple.500',
    buttonHoverColor: 'purple.600',
    shadowColor: 'rgba(156, 39, 176, 0.3)',
    downloadFileName: 'resilient_network_badge.png',
    shareText: 'Bitcoin proved resilient in my Adoption Simulation on TimechainStats.com! 💪🔗🛡️'
  },
  bitcoinCenturyClub: {
    backgroundColor: '#795548',
    emoji: '🏛️🔗💯',
    title: 'Bitcoin Century Club',
    subtitle: 'Reached 100 generations',
    buttonColor: 'gray.500',
    buttonHoverColor: 'gray.600',
    shadowColor: 'rgba(121, 85, 72, 0.3)',
    downloadFileName: 'bitcoin_century_club_badge.png',
    shareText: 'I joined the Bitcoin Century Club in the Adoption Simulation on TimechainStats.com! 🏛️🔗💯'
  },
  // NonceHunter
  bitcoinMiningNovice: {
    backgroundColor: '#4CAF50',
    emoji: '⛏️🔰💻',
    title: 'Bitcoin Mining Novice',
    subtitle: 'First successful nonce found!',
    buttonColor: 'green.500',
    buttonHoverColor: 'green.600',
    shadowColor: 'rgba(76, 175, 80, 0.3)',
    downloadFileName: 'bitcoin_mining_novice_badge.png',
    shareText: 'I found my first nonce in the Nonce Hunter game on TimechainStats.com! ⛏️🔰💻'
  },
  bitcoinMiningExpert: {
    backgroundColor: '#2196F3',
    emoji: '⛏️🏆💻',
    title: 'Bitcoin Mining Expert',
    subtitle: 'Level 3 achieved in Nonce Hunter',
    buttonColor: 'blue.500',
    buttonHoverColor: 'blue.600',
    shadowColor: 'rgba(33, 150, 243, 0.3)',
    downloadFileName: 'bitcoin_mining_expert_badge.png',
    shareText: 'I reached expert level in the Nonce Hunter game on TimechainStats.com! ⛏️🏆💻'
  },
  bitcoinMiningMaster: {
    backgroundColor: '#FFC107',
    emoji: '⛏️👑💻',
    title: 'Bitcoin Mining Master',
    subtitle: 'Level 5 achieved in Nonce Hunter',
    buttonColor: 'yellow.500',
    buttonHoverColor: 'yellow.600',
    shadowColor: 'rgba(255, 193, 7, 0.3)',
    downloadFileName: 'bitcoin_mining_master_badge.png',
    shareText: 'I mastered the Nonce Hunter game on TimechainStats.com! ⛏️👑💻'
  }
};
